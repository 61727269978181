<template>
  <div>
    <CloResultsGallery
      v-if="list.length"
      :list="list"
      :mediaDownloadUrl="mediaDownloadUrl"
      integratedLightbox
    />
  </div>
</template>

<script>
import axios from "axios";
//import CloMediaFeed from "@/media/CloMediaFeed/CloMediaFeed";
//import CloResultsGallery from "@/media/CloResultsGallery/CloResultsGallery";
import CloResultsGallery from "./CloResultsGallery.vue";

export default {
  components: {
    //CloMediaFeed,
    CloResultsGallery,
  },
  props: {
    mediaSearchApi: {
      type: String,
    },
    mediaDownloadUrl: {
      type: String,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.getMedia();
  },
  methods: {
    getMedia() {
      axios
        .get(this.mediaSearchApi, {
          params: {
            src: "efish",
            count: 10,
          },
        })
        .then((res) => {
          //console.log(res.data);
          this.list = res.data.results.content;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
