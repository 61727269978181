<template>
  <div>
    <ol class="UnorderedList UnorderedList--flat">
      <li v-for="sp in speciesList" :key="sp.speciesCode" class="u-stack-lg">
        <div class="SectionHeading u-stack-xs">
          <span class="SectionHeading-heading">
            <h2 :id="sp.speciesCode" class="Heading Heading--h4">
              <span class="Heading-main">{{ sp.primary }}</span>
              <span class="Heading-sub Heading-sub--inline Heading-sub--sci">{{
                sp.sciName
              }}</span>
            </h2>
          </span>
          <span class="SectionHeading-link">
            <a
              :href="
                mediaSearchUrl +
                '?searchField=animals&src=efish&taxonCode=' +
                sp.speciesCode +
                '&q=' +
                sp.primary +
                '%20-%20' +
                sp.sciName
              "
              >{{ viewPhotos }}
              <span class="is-visuallyHidden">{{ sp.sciName }}</span></a
            >
          </span>
        </div>

        <div class="common-names">
          <div v-if="sp.Bolivia">
            <span class="bold">Bolivia:</span> {{ sp.Bolivia }}
          </div>
          <div v-if="sp.Brasil">
            <span class="bold">Brasil:</span> {{ sp.Brasil }}
          </div>
          <div v-if="sp.Colombia">
            <span class="bold">Colombia:</span> {{ sp.Colombia }}
          </div>
          <div v-if="sp.Ecuador">
            <span class="bold">Ecuador:</span> {{ sp.Ecuador }}
          </div>
          <div v-if="sp.Perú">
            <span class="bold">Perú:</span> {{ sp.Perú }}
          </div>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import speciesList from "./SpeciesList.json";

export default {
  props: {
    mediaSearchUrl: {
      type: String,
    },
    viewPhotos: {
      type: String,
    },
  },
  data() {
    return {
      speciesList: speciesList,
    };
  },
};
</script>

<style lang="scss">
.common-names {
  @include text(3);
  @include stack-sm;
  .bold {
    font-weight: bold;
  }
}
</style>
