<template>
  <div class="ResultsGallery">
    <div :style="{ paddingTop: gap + 'px' }" class="ResultsGallery-background">
      <div
        v-for="(row, rowIndex) in rows"
        :key="rowIndex"
        class="ResultsGallery-row"
        :style="{ marginLeft: gap + 'px' }"
      >
        <a
          v-for="(item, index) in row"
          :key="index"
          class="ResultsGallery-link"
          :href="item.specimenUrl"
          :ref="'asset-' + item.assetId"
          :data-asset-id="item.assetId"
          @mouseover="mouseover(rowIndex, index)"
          @mouseout="mouseout(rowIndex, index)"
          @focus="mouseover(rowIndex, index)"
          @blur="mouseout(rowIndex, index)"
          @click.exact="openLightbox($event, item.assetId)"
        >
          <figure
            class="ResultsGallery-figure"
            :style="{ marginRight: gap + 'px', marginBottom: gap + 'px' }"
          >
            <img
              :alt="item.commonName + ' - ' + item.userDisplayName"
              class="ResultsGallery-image"
              v-if="
                !item.error && item.mediaType == 'Photo' && responsiveImages
              "
              @load="loaded"
              @error="errored(item.assetId)"
              :class="{ crop: item.crop }"
              :style="item.calc"
              :sizes="item.calc.width"
              :srcset="
                item.previewUrl +
                '160 160w,' +
                item.previewUrl +
                '320 320w,' +
                item.previewUrl +
                '480 480w,' +
                item.previewUrl +
                '640 640w,' +
                item.previewUrl +
                '900 900w,' +
                item.previewUrl +
                '1200 1200w'
              "
              :src="item.previewUrl + '480'"
            />
            <img
              :alt="item.commonName + ' - ' + item.userDisplayName"
              class="ResultsGallery-image"
              v-if="
                !item.error && item.mediaType == 'Photo' && !responsiveImages
              "
              @load="loaded"
              @error="errored(item.assetId)"
              :class="{ crop: item.crop }"
              :style="item.calc"
              :src="item.previewUrl + forceImageSize"
            />
            <img
              :alt="item.commonName + ' - ' + item.userDisplayName"
              class="ResultsGallery-image"
              v-if="!item.error && item.mediaType != 'Photo'"
              @load="loaded"
              @error="errored(item.assetId)"
              :class="{ crop: item.crop }"
              :style="item.calc"
              :src="item.previewUrl"
            />
            <div
              class="ResultsGallery-placeholder"
              v-if="item.error"
              :style="item.calc"
            >
              <div>
                <CloIcon symbol="timer" />
                <span v-if="item.mediaType == 'Photo'">{{
                  $t("media.processingPhoto")
                }}</span>
                <span v-if="item.mediaType == 'Audio'">{{
                  $t("media.processingAudio")
                }}</span>
                <span v-if="item.mediaType == 'Video'">{{
                  $t("media.processingVideo")
                }}</span>
              </div>
            </div>
            <figcaption
              class="ResultsGallery-caption"
              :class="{
                show: item.showCaption,
                withPlayButton: item.mediaType != 'Photo',
              }"
            >
              <CloIcon
                v-if="item.valid == 'false'"
                class="ResultsGallery-icon"
                symbol="warning"
                size="sm"
              />
              <CloIcon
                v-if="
                  item.assetRestricted ||
                  item.parentAssetId ||
                  item.collection == 'production'
                "
                class="ResultsGallery-icon"
                symbol="warning"
                size="lockClosed"
              />
              <CloIcon
                v-if="item.playable == 'false'"
                class="ResultsGallery-icon"
                symbol="audioMissing"
                size="sm"
              />
              <CloIcon
                v-if="item.assetState == 'asset_deleted'"
                class="ResultsGallery-icon"
                symbol="delete"
                size="sm"
              />
              <template v-if="caption == 'species'">
                <span>
                  <strong>{{ item.commonName }}</strong>
                </span>

                <span>&copy;&nbsp;{{ item.userDisplayName }}</span>
              </template>
              <template v-else>
                <span>
                  <strong>&copy;&nbsp;{{ item.userDisplayName }}</strong>
                </span>
                <span>Macaulay Library</span>
              </template>
            </figcaption>
            <div
              v-if="item.mediaType != 'Photo'"
              class="ResultsGallery-playButton"
              aria-hidden="true"
            >
              <CloIcon symbol="play" size="sm" />
            </div>
          </figure>
        </a>
      </div>
      <div
        v-if="totalDone < mediaList.length || loading"
        class="LoadingAnimation LoadingAnimation--showMore"
      >
        <CloIcon symbol="spinner" />
      </div>
      <div v-if="error" class="ResultsGallery-unavailable">
        <div>{{ $t("generic.unavailable") }}</div>
      </div>
    </div>
    <CloLightbox
      reverse
      :list="list"
      source="search"
      :ratingEnabled="ratingEnabled"
      :getRatingsUrl="getRatingsUrl"
      :saveRatingUrl="saveRatingUrl"
      :ratingCsrf="ratingCsrf"
      v-if="lightboxOpen"
      v-on:close="closeLightbox"
      :goTo="goToAsset"
      :speciesLinks="speciesLinks"
    />
  </div>
</template>

<script>
import axios from "axios";

import CloIcon from "@/icons/CloIcon/CloIcon";
//import CloLightbox from "@/media/CloLightbox/CloLightbox";
import CloLightbox from "./CloLightbox.vue";

export default {
  components: {
    CloIcon,
    CloLightbox,
  },
  data() {
    return {
      active: false,
      lightboxKey: "lightboxKey",
      lightboxOpen: false,
      goToAsset: null,
      gap: 1,
      mediaList: [],
      totalDone: 0,
      rows: [],
      loading: false,
      error: false,
      height: null,
      forceImageSize: 640,
      small: {
        height: null,
        forceImageSize: 640,
      },
      medium: {
        height: null,
        forceImageSize: 640,
      },
    };
  },
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    url: {
      type: String,
      default: null,
    },
    mediaDownloadUrl: {
      default: "https://cdn.download.ams.birds.cornell.edu/api/v1/asset/",
      type: String,
    },
    caption: {
      type: String,
      default: "species",
    },
    responsiveImages: {
      type: Boolean,
      default: true,
    },
    rowHeightForSmall: {
      type: Number,
      default: null,
    },
    rowHeightForMedium: {
      type: Number,
      default: null,
    },
    integratedLightbox: {
      type: Boolean,
      default: false,
    },
    externalLightbox: {
      type: Boolean,
      default: false,
    },
    ratingEnabled: {
      type: Boolean,
      default: false,
    },
    getRatingsUrl: {
      type: String,
      default: "/rating-api?assetIds=",
    },
    saveRatingUrl: {
      type: String,
      default: "/rating-api",
    },
    ratingApiHeader: {
      type: String,
      default: null,
    },
    ratingCsrf: {
      type: String,
      default: null,
    },
    speciesLinks: {
      type: Boolean,
      default: true,
    },
  },
  beforeMount() {
    this.refreshSize();
  },
  computed: {
    sixteenByNine() {
      return 16.0 / 9.0;
    },
  },
  watch: {
    rowHeightForSmall() {
      this.refreshSize();
      this.refresh();
    },
    rowHeightForMedium() {
      this.refreshSize();
      this.refresh();
    },
    list() {
      this.mediaList = this.list;
      //this.initialize();
      this.prepareList();
      this.refresh();
    },
    url() {
      this.getListFromUrl();
    },
  },
  mounted() {
    this.containerWidth = this.$el.offsetWidth;
    if (!this.url) {
      // not fetching from API
      this.mediaList = this.list;
      this.initialize();
    } else {
      //lightboxData[this.lightboxKey] = {};
      this.getListFromUrl();
    }

    // refresh on resize
    document.addEventListener("lichen:resize", () => {
      if (this.$el.offsetWidth !== this.containerWidth) {
        this.containerWidth = this.$el.offsetWidth;
        this.refreshSize();
        this.refresh();
      }
    });

    // listen for data, get full list each time
    // TODO: use prop instead
    this.$el.addEventListener("lichen:resultsgallery-setlist", (e) => {
      //console.log('got list', this._uid);
      this.mediaList = e.detail;
      this.prepareList();
      this.refresh();
    });

    // listen for size event
    // TODO: use prop instead
    this.$el.addEventListener("lichen:resultsgallery-setsize", (e) => {
      //console.log('got size', e.detail);
      /*
			this.small.height = e.detail.small.height;
			this.small.forceImageSize = e.detail.small.forceImageSize;
			this.medium.height = e.detail.medium.height;
			this.medium.forceImageSize = e.detail.medium.forceImageSize;
			this.refreshSize();
			this.refresh();
			*/
    });
  },
  methods: {
    getListFromUrl() {
      this.loading = true;
      this.error = false;
      axios
        .get(this.url)
        .then((res) => {
          //console.log(res.data.results.content);
          this.mediaList = res.data.results.content;
          // populate lightbox data
          //lightboxData[this.lightboxKey] = res.data;

          var resultCountEvent = new CustomEvent(
            "lichen:resultsgallery-numresults",
            {
              detail: { numResults: res.data.results.count },
            }
          );
          document.dispatchEvent(resultCountEvent);

          // get it started
          this.initialize();
          this.loading = false;
        })
        .catch(() => {
          //console.log(err)
          this.loading = false;
          this.error = true;
        });
    },
    prepareList() {
      this.mediaList.forEach((item, index) => {
        if (item.mediaType === "Audio") {
          this.mediaList[index].ratio = 32.0 / 11.0;
          //this.mediaList[index].ratio = this.sixteenByNine;
        } else if (item.mediaType === "Video") {
          this.mediaList[index].ratio = this.sixteenByNine;
        } else if (item.width && item.height) {
          this.mediaList[index].ratio = item.width / item.height;
        }
      });
    },
    refreshSize() {
      if (window.innerWidth < 768) {
        if (this.rowHeightForSmall) {
          this.height = this.rowHeightForSmall;
        }
        if (!this.responsiveImages) {
          this.forceImageSize = 480;
        }
      } else {
        if (this.rowHeightForMedium) {
          this.height = this.rowHeightForMedium;
          if (!this.responsiveImages) {
            this.forceImageSize = 900;
          }
        } else {
          if (!this.responsiveImages) {
            this.forceImageSize = 480;
          }
        }
      }
    },
    initialize() {
      this.prepareList();
      this.calculateHeight();
      this.addRow(0, 0, this.mediaList.length);
    },
    refresh() {
      this.rows = [];
      this.totalDone = 0;
      this.calculateHeight();
      this.addRow(0, 0, this.mediaList.length);
    },
    mouseover(row, item) {
      this.rows[row][item].showCaption = true;
      this.$forceUpdate();
    },
    mouseout(row, item) {
      this.rows[row][item].showCaption = false;
      this.$forceUpdate();
    },
    loaded(e) {
      //console.log('loaded', e)
      e.target.classList.add("show");
    },
    errored(assetId) {
      //console.log('errored', assetId);
      this.rows.forEach((row, rowIndex) => {
        row.forEach((item, itemIndex) => {
          if (assetId === item.assetId) {
            this.rows[rowIndex][itemIndex].error = true;
          }
        });
      });
      this.$forceUpdate();
    },
    calculateHeight() {
      // use container and window size to calculate ideal max row height
      if (!this.height) {
        let containerWidth = this.$el.offsetWidth;
        // this is a decent calculation for desktop
        this.height = containerWidth / 6.3;
        // for small screens, need a minimum height
        if (this.height < 150) {
          this.height = 150;
        }
      }
    },
    addItem(rowIndex, listIndex, height) {
      let item = this.mediaList[listIndex];
      let ratio = this.mediaList[listIndex].ratio;
      if (ratio > 3) {
        ratio = 3;
        item.crop = true;
      }
      let width = height * ratio - this.gap;
      let calc = {
        width: width + "px",
        height: height + "px",
      };
      item.calc = calc;
      item.showCaption = false;
      this.totalDone++;
      //console.log('add item', listIndex)
      this.rows[rowIndex].push(item);
    },
    addRow(rowIndex, start, end) {
      let rowInfo = this.calculateRow(start, end);
      if (rowInfo) {
        this.rows[rowIndex] = [];
        //console.log('add row', rowIndex, 'with height', rowInfo.height, 'start', start, 'count', rowInfo.count);
        for (
          let i = start;
          i < start + rowInfo.count && i < this.mediaList.length;
          i++
        ) {
          this.addItem(rowIndex, i, rowInfo.height);
        }
        this.$forceUpdate();
        //console.log(this.totalDone, 'done. list:', this.mediaList.length)
        if (this.totalDone < this.mediaList.length) {
          this.addRow(rowIndex + 1, this.totalDone, end);
        }
      } else {
        setTimeout(() => {
          this.addRow(rowIndex, start, end);
        }, 100);
      }
    },
    calculateRow(start, end) {
      let rowWidth = 0;
      let i = start;
      let count = 0;
      let containerWidth = this.$el.offsetWidth;
      let allImagesLoaded = true;
      while (i < end && rowWidth <= containerWidth) {
        //console.log('has ratio?', i, this.mediaList[i].ratio)
        if (this.mediaList[i].hasOwnProperty("ratio")) {
          let ratio = this.mediaList[i].ratio;
          if (ratio > 3) {
            ratio = 3;
          }
          let width = this.height * ratio - this.gap;
          rowWidth = rowWidth + width;
          //console.log('   loop', i, rowWidth);
          i++;
          count++;
        } else {
          let ratio = this.sixteenByNine;
          let width = this.height * ratio - this.gap;
          rowWidth = rowWidth + width;

          let item = this.mediaList[i];
          //console.log(i, item.mediaType, item.width, item.height)
          if (
            item.hasOwnProperty("width") &&
            item.hasOwnProperty("height") &&
            item.width != null &&
            item.height != null
          ) {
            item.ratio = item.width / item.height;
          } else {
            let img = new Image();
            img.onload = () => {
              this.mediaList[img.lichenIndex].width = img.width;
              this.mediaList[img.lichenIndex].height = img.height;
              this.mediaList[img.lichenIndex].ratio = img.width / img.height;
              //console.log('loaded', img.lichenIndex, this.mediaList[img.lichenIndex].ratio);
            };
            img.onerror = () => {
              this.mediaList[img.lichenIndex].width = 16;
              this.mediaList[img.lichenIndex].height = 9;
              this.mediaList[img.lichenIndex].ratio = this.sixteenByNine;
              this.mediaList[img.lichenIndex].error = true;
              //console.log('error', img.lichenIndex, this.mediaList[img.lichenIndex].ratio);
            };
            img.lichenIndex = i;
            if (this.forceImageSize) {
              img.src = item.previewUrl + this.forceImageSize;
              this.mediaList[img.lichenIndex].forceImageSize =
                this.forceImageSize;
            } else {
              img.src = item.previewUrl + "160";
            }
          }
          //console.log('   loop', i, rowWidth);
          i++;
          count++;
          allImagesLoaded = false;
        }
      }
      if (!allImagesLoaded) {
        //console.log('trying again');
        return false;
      }
      //console.log('row', rowWidth, 'container', containerWidth);
      if (rowWidth >= containerWidth) {
        let rowAspectRatio = rowWidth / this.height;
        return {
          height: containerWidth / rowAspectRatio - this.gap,
          count: count,
        };
      } else {
        return {
          height: this.height * 0.8,
          count: count,
        };
      }
    },
    openLightbox(e, assetId) {
      if (this.integratedLightbox && !e.metaKey) {
        e.preventDefault();
        this.goToAsset = assetId;
        this.lightboxOpen = true;
      } else if (this.externalLightbox && !e.metaKey) {
        e.preventDefault();
        this.$emit("open-lightbox", assetId);
      }
    },
    closeLightbox() {
      this.lightboxOpen = false;
      //this.$refs['asset-' + this.goToAsset][0].focus();
    },
  },
};
</script>

<style lang="scss">
.ResultsGallery {
  width: 100%;
  .ResultsGallery-background {
    background-color: $color-white;
  }
  .ResultsGallery-row {
    display: flex;
    flex-direction: row;
    max-width: 100%; // needed?
    overflow-x: hidden; // needed?
  }
  .ResultsGallery-link {
    cursor: pointer;
    text-decoration: none;
  }
  .ResultsGallery-figure {
    position: relative;
    margin: 0;
  }
  .ResultsGallery-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    @include text(2);
    @include inset-xs;
    @include colorReverse;
    background-color: $color-overlay-black;
    margin: 0;
    opacity: 0;
    transition: opacity 200ms ease;
    &.show {
      opacity: 1;
    }
    &.withPlayButton {
      padding-left: 3rem;
      [dir="rtl"] & {
        padding-left: 0;
        padding-right: 3rem;
      }
    }
    strong {
      padding-right: $space-sm;
      [dir="rtl"] & {
        padding-left: $space-sm;
        padding-right: 0;
      }
    }
  }
  .ResultsGallery-image {
    display: block;
    max-width: none;
    transition: opacity 200ms ease, transform 200ms ease;
    opacity: 0;
    &.show {
      opacity: 1;
    }
    &.crop {
      object-fit: cover;
    }
  }
  .ResultsGallery-placeholder {
    background-color: $color-neutral-2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-neutral-5;
    @include text(2);
    .Icon {
      fill: $color-neutral-3;
    }
  }
  .ResultsGallery-playButton {
    background-color: $color-primary;
    color: $color-white;
    line-height: 1;
    position: absolute;
    left: $space-sm;
    [dir="rtl"] & {
      left: auto;
      right: $space-sm;
    }
    bottom: $space-sm;
    border-radius: $radius-sm;
    margin: 0;
    @include inset-squish-sm;
  }
  .ResultsGallery-icon {
    @include icon-sm;
    fill: $color-alert;
  }
}
</style>
