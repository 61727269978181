var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ResultsGallery"},[_c('div',{staticClass:"ResultsGallery-background",style:({ paddingTop: _vm.gap + 'px' })},[_vm._l((_vm.rows),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"ResultsGallery-row",style:({ marginLeft: _vm.gap + 'px' })},_vm._l((row),function(item,index){return _c('a',{key:index,ref:'asset-' + item.assetId,refInFor:true,staticClass:"ResultsGallery-link",attrs:{"href":item.specimenUrl,"data-asset-id":item.assetId},on:{"mouseover":function($event){return _vm.mouseover(rowIndex, index)},"mouseout":function($event){return _vm.mouseout(rowIndex, index)},"focus":function($event){return _vm.mouseover(rowIndex, index)},"blur":function($event){return _vm.mouseout(rowIndex, index)},"click":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.openLightbox($event, item.assetId)}}},[_c('figure',{staticClass:"ResultsGallery-figure",style:({ marginRight: _vm.gap + 'px', marginBottom: _vm.gap + 'px' })},[(
              !item.error && item.mediaType == 'Photo' && _vm.responsiveImages
            )?_c('img',{staticClass:"ResultsGallery-image",class:{ crop: item.crop },style:(item.calc),attrs:{"alt":item.commonName + ' - ' + item.userDisplayName,"sizes":item.calc.width,"srcset":item.previewUrl +
              '160 160w,' +
              item.previewUrl +
              '320 320w,' +
              item.previewUrl +
              '480 480w,' +
              item.previewUrl +
              '640 640w,' +
              item.previewUrl +
              '900 900w,' +
              item.previewUrl +
              '1200 1200w',"src":item.previewUrl + '480'},on:{"load":_vm.loaded,"error":function($event){return _vm.errored(item.assetId)}}}):_vm._e(),(
              !item.error && item.mediaType == 'Photo' && !_vm.responsiveImages
            )?_c('img',{staticClass:"ResultsGallery-image",class:{ crop: item.crop },style:(item.calc),attrs:{"alt":item.commonName + ' - ' + item.userDisplayName,"src":item.previewUrl + _vm.forceImageSize},on:{"load":_vm.loaded,"error":function($event){return _vm.errored(item.assetId)}}}):_vm._e(),(!item.error && item.mediaType != 'Photo')?_c('img',{staticClass:"ResultsGallery-image",class:{ crop: item.crop },style:(item.calc),attrs:{"alt":item.commonName + ' - ' + item.userDisplayName,"src":item.previewUrl},on:{"load":_vm.loaded,"error":function($event){return _vm.errored(item.assetId)}}}):_vm._e(),(item.error)?_c('div',{staticClass:"ResultsGallery-placeholder",style:(item.calc)},[_c('div',[_c('CloIcon',{attrs:{"symbol":"timer"}}),(item.mediaType == 'Photo')?_c('span',[_vm._v(_vm._s(_vm.$t("media.processingPhoto")))]):_vm._e(),(item.mediaType == 'Audio')?_c('span',[_vm._v(_vm._s(_vm.$t("media.processingAudio")))]):_vm._e(),(item.mediaType == 'Video')?_c('span',[_vm._v(_vm._s(_vm.$t("media.processingVideo")))]):_vm._e()],1)]):_vm._e(),_c('figcaption',{staticClass:"ResultsGallery-caption",class:{
              show: item.showCaption,
              withPlayButton: item.mediaType != 'Photo',
            }},[(item.valid == 'false')?_c('CloIcon',{staticClass:"ResultsGallery-icon",attrs:{"symbol":"warning","size":"sm"}}):_vm._e(),(
                item.assetRestricted ||
                item.parentAssetId ||
                item.collection == 'production'
              )?_c('CloIcon',{staticClass:"ResultsGallery-icon",attrs:{"symbol":"warning","size":"lockClosed"}}):_vm._e(),(item.playable == 'false')?_c('CloIcon',{staticClass:"ResultsGallery-icon",attrs:{"symbol":"audioMissing","size":"sm"}}):_vm._e(),(item.assetState == 'asset_deleted')?_c('CloIcon',{staticClass:"ResultsGallery-icon",attrs:{"symbol":"delete","size":"sm"}}):_vm._e(),(_vm.caption == 'species')?[_c('span',[_c('strong',[_vm._v(_vm._s(item.commonName))])]),_c('span',[_vm._v("© "+_vm._s(item.userDisplayName))])]:[_c('span',[_c('strong',[_vm._v("© "+_vm._s(item.userDisplayName))])]),_c('span',[_vm._v("Macaulay Library")])]],2),(item.mediaType != 'Photo')?_c('div',{staticClass:"ResultsGallery-playButton",attrs:{"aria-hidden":"true"}},[_c('CloIcon',{attrs:{"symbol":"play","size":"sm"}})],1):_vm._e()])])}),0)}),(_vm.totalDone < _vm.mediaList.length || _vm.loading)?_c('div',{staticClass:"LoadingAnimation LoadingAnimation--showMore"},[_c('CloIcon',{attrs:{"symbol":"spinner"}})],1):_vm._e(),(_vm.error)?_c('div',{staticClass:"ResultsGallery-unavailable"},[_c('div',[_vm._v(_vm._s(_vm.$t("generic.unavailable")))])]):_vm._e()],2),(_vm.lightboxOpen)?_c('CloLightbox',{attrs:{"reverse":"","list":_vm.list,"source":"search","ratingEnabled":_vm.ratingEnabled,"getRatingsUrl":_vm.getRatingsUrl,"saveRatingUrl":_vm.saveRatingUrl,"ratingCsrf":_vm.ratingCsrf,"goTo":_vm.goToAsset,"speciesLinks":_vm.speciesLinks},on:{"close":_vm.closeLightbox}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }