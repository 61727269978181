<template>
  <div>
    <transition name="fade">
      <div
        v-if="open"
        class="Lightbox"
        :class="classes"
        role="dialog"
        tabindex="-1"
        ref="lightbox"
        @keyup.escape.stop.prevent="close"
        @keydown.left.stop.prevent="left"
        @keydown.right.stop.prevent="right"
        @keyup.space.stop.prevent="toggleMediaPlay"
        @keyup.tab="handleTab"
        @keyup="handleRating"
      >
        <div tabindex="0" ref="focusTrapFirst" class="is-visuallyHidden"></div>

        <div class="is-visuallyHidden" role="status" aria-live="polite">
          {{ lightboxInstructions }}
        </div>

        <button type="button" class="Lightbox-close" @click="close" ref="close">
          <span class="is-visuallyHidden">{{ $t("generic.Close") }}</span>
          <CloIcon symbol="close" />
        </button>
        <!-- this needs scroll-behavior:smooth when animate is true if using native browser animation for prev/next -->
        <ol
          class="Lightbox-carousel"
          :style="scrollsnap ? 'scroll-snap-type: x mandatory;' : ''"
          @scroll="handleScroll"
          ref="carousel"
        >
          <li
            v-for="(item, index) in mediaList"
            :key="index"
            class="Lightbox-slide"
            tabindex="0"
            ref="slides"
          >
            <figure
              class="Lightbox-figure"
              v-if="Math.abs(currentSlide - index) <= 1"
            >
              <div class="Lightbox-media-outer">
                <transition name="fade">
                  <div
                    v-if="index === currentSlide"
                    class="Lightbox-media-inner"
                    ref="mediaContainer"
                  >
                    <img
                      v-if="item.mediaType === 'p'"
                      class="Lightbox-image"
                      :alt="item.title ? item.title : item.comName"
                      :src="mediaDownloadUrl + item.assetId + '/1200'"
                      :srcset="
                        mediaDownloadUrl +
                        item.assetId +
                        '/160 160w,' +
                        mediaDownloadUrl +
                        item.assetId +
                        '/360 360w,' +
                        mediaDownloadUrl +
                        item.assetId +
                        '/480 480w,' +
                        mediaDownloadUrl +
                        item.assetId +
                        '/640 640w,' +
                        mediaDownloadUrl +
                        item.assetId +
                        '/900 900w,' +
                        mediaDownloadUrl +
                        item.assetId +
                        '/1200 1200w,' +
                        mediaDownloadUrl +
                        item.assetId +
                        '/1800 1800w,' +
                        mediaDownloadUrl +
                        item.assetId +
                        '/2400 2400w'
                      "
                      sizes="100vw"
                    />
                    <CloSpectrogramPlayer
                      v-if="item.mediaType === 'a'"
                      :assetId="item.assetId"
                      :mediaDownloadUrl="mediaDownloadUrl"
                      :citationUrl="item.citationUrl"
                      :autoplay="autoplay"
                      :timeline="true"
                      :size="
                        maxHeight < 400 ? (maxHeight < 300 ? 'xs' : 'sm') : ''
                      "
                    />
                    <CloVideoPlayer
                      v-if="item.mediaType === 'v'"
                      :assetId="item.assetId"
                      :mediaDownloadUrl="mediaDownloadUrl"
                      :citationUrl="item.citationUrl"
                      :autoplay="autoplay"
                      :poster="mediaDownloadUrl + item.assetId + '/thumb'"
                      :maxHeight="maxHeight"
                      :maxWidth="maxWidth"
                    />
                  </div>
                </transition>
              </div>
              <figcaption class="Lightbox-caption">
                <transition name="fade">
                  <div
                    v-if="Math.abs(currentSlide - index) <= 1"
                    class="Lightbox-caption-inner"
                  >
                    <div class="Lightbox-caption-main">
                      <div class="Lightbox-heading">
                        <div
                          v-if="item.title || item.description"
                          class="Lightbox-galleryLabels"
                        >
                          <div
                            v-if="item.title"
                            class="Heading Heading--h3"
                            :class="reverse ? 'Heading--reverse' : ''"
                            v-html="item.title"
                          ></div>
                          <div
                            v-if="item.caption"
                            v-html="item.caption"
                            class="u-stack-sm"
                          ></div>
                        </div>
                        <div class="Lightbox-speciesLabels">
                          <span
                            v-if="item.valid === 'false'"
                            class="Lightbox-unconfirmed"
                          >
                            <CloIcon symbol="warning" />
                            <span>{{ $t("mediaSearch.unconfirmed") }}</span>
                          </span>
                          <span
                            class="Heading Heading--h4 u-margin-none"
                            :class="reverse ? 'Heading--reverse' : ''"
                          >
                            <a
                              v-if="item.speciesUrl && speciesLinks"
                              :href="item.speciesUrl"
                            >
                              <span class="Heading-main">
                                {{ item.comName }}
                              </span>
                              <span
                                class="
                                  Heading-sub
                                  Heading-sub--sci
                                  Heading-sub--inline
                                "
                              >
                                {{ item.sciName }}
                              </span>
                            </a>
                            <template v-else>
                              <span class="Heading-main">
                                {{ item.comName }}
                              </span>
                              <span
                                class="
                                  Heading-sub
                                  Heading-sub--sci
                                  Heading-sub--inline
                                "
                              >
                                {{ item.sciName }}
                              </span>
                            </template>
                          </span>
                        </div>
                      </div>
                      <div v-if="ratingEnabled" class="Lightbox-rating">
                        <CloRating
                          :reverse="reverse"
                          :mediaType="item.mediaType"
                          :assetId="item.assetId"
                          :rating="item.rating"
                          :count="item.ratingCount"
                          :userRating="item.userRating"
                          :ratingFromParent="item.ratingFromParent"
                          :getRatingUrl="getRatingUrl"
                          :saveRatingUrl="saveRatingUrl"
                          :ratingApiHeader="ratingApiHeader"
                          :ratingCsrf="ratingCsrf"
                          v-on:rating-update="applyRatingToList"
                          class="u-stack-xs"
                        />
                      </div>
                    </div>
                    <div
                      class="Lightbox-caption-details"
                      :style="
                        details
                          ? 'max-height:none;overflow:visible;'
                          : 'max-height:0px;overflow:hidden;'
                      "
                    >
                      <div class="Lightbox-obs">
                        <div v-if="item.userDisplayName">
                          <CloIcon symbol="user" aria-hidden="true" />
                          <a
                            v-if="item.userProfileUrl"
                            :href="item.userProfileUrl"
                          >
                            {{ item.userDisplayName }}
                          </a>
                          <span v-else>
                            {{ item.userDisplayName }}
                          </span>
                        </div>
                        <div v-if="item.date">
                          <CloIcon symbol="date" aria-hidden="true" />
                          <span>{{ item.date }}</span>
                        </div>
                        <div v-if="item.location">
                          <CloIcon
                            symbol="locationGeneric"
                            aria-hidden="true"
                          />
                          <span>{{ item.location }}</span>
                        </div>
                        <div v-if="item.credit">
                          <span>{{ item.credit }}</span>
                        </div>
                      </div>
                      <div class="Lightbox-meta">
                        <div v-if="item.details">
                          <span class="Lightbox-meta-label">{{
                            $t("alert.comments")
                          }}</span>
                          <span>{{ item.details }} </span>
                        </div>
                        <template v-if="item.source === 'ebird'">
                          <div v-if="item.age">
                            <span class="Lightbox-meta-label">{{
                              $t("obsMedia.age")
                            }}</span>
                            <span>{{ item.age }} </span>
                          </div>
                          <div v-if="item.sex">
                            <span class="Lightbox-meta-label">{{
                              $t("obsMedia.sex")
                            }}</span>
                            <span>{{ item.sex }} </span>
                          </div>
                          <!-- the old way of showing tags
														<div v-if="item.behaviors">
															<span class="Lightbox-meta-label">{{ $t('obsMedia.behaviors') }}</span>
														<span>{{ item.behaviors }} </span>
														</div>
													-->
                        </template>
                        <template v-else>
                          <div v-if="item.ageSexCounts">
                            <span class="Lightbox-meta-label">{{
                              $t("mediaTag.ageSex.label")
                            }}</span>
                            <span>{{ item.ageSexCounts }} </span>
                          </div>
                        </template>
                        <template v-if="item.tags">
                          <div v-if="item.tags.sounds">
                            <span class="Lightbox-meta-label">{{
                              $t("mediaTag.sounds.label")
                            }}</span>
                            <span>{{ item.tags.sounds }} </span>
                          </div>
                          <div v-if="item.tags.behaviors">
                            <span class="Lightbox-meta-label">{{
                              $t("mediaTag.behaviors.label")
                            }}</span>
                            <span>{{ item.tags.behaviors }} </span>
                          </div>
                          <div v-if="item.tags.breeding">
                            <span class="Lightbox-meta-label">{{
                              $t("mediaTag.breeding.label")
                            }}</span>
                            <span>{{ item.tags.breeding }} </span>
                          </div>
                          <div v-if="item.tags.tags">
                            <span class="Lightbox-meta-label">{{
                              $t("mediaTag.tags.label")
                            }}</span>
                            <span>{{ item.tags.tags }} </span>
                          </div>
                        </template>
                      </div>
                      <div class="Lightbox-links">
                        <div v-if="item.source === 'ebird'">
                          <a :href="item.checklistUrl + '#' + item.assetId">
                            <CloIcon symbol="flag" />
                            {{ $t("obsMedia.report") }}
                          </a>
                        </div>
                        <div v-if="item.source === 'efish'">
                          <a
                            :href="
                              'https://docs.google.com/forms/d/e/1FAIpQLSdsH0Lq8MLFbQAjvk9F8Pl0ofTCMi99DIYe4NJiKNTqVdAC9g/viewform?usp=pp_url&entry.929331583=' +
                              item.assetId +
                              '&entry.1813915544=' +
                              item.location
                            "
                          >
                            <CloIcon symbol="flag" />
                            {{ $t("obsMedia.report") }}
                          </a>
                        </div>
                        <div v-if="item.source === 'ml'">
                          <a
                            :href="
                              'https://docs.google.com/forms/d/e/1FAIpQLSezIQfTkW97sktHJhWjsAx5PA-VffPnyOmF2GSgI3FBcWfDcA/viewform?usp=pp_url&entry.929331583=' +
                              item.assetId
                            "
                          >
                            <CloIcon symbol="flag" />
                            {{ $t("obsMedia.report") }}
                          </a>
                        </div>
                        <div v-if="item.checklistId && item.source !== 'efish'">
                          <a :href="item.checklistUrl">
                            <CloIcon symbol="checklist" />
                            {{ $t("mediaSearch.eBirdChecklist", ["eBird"]) }}
                            {{ item.checklistId }}
                          </a>
                        </div>
                        <div v-if="item.citationUrl">
                          <a :href="item.citationUrl">
                            <CloIcon symbol="link" />
                            Macaulay Library {{ item.citationName }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </figcaption>
            </figure>
          </li>
        </ol>

        <div class="Lightbox-navigation" :style="'top:' + maxHeight / 2 + 'px'">
          <button
            type="button"
            class="Lightbox-toggleDetails"
            @click="toggleDetails"
          >
            <span v-if="!details">{{ $t("checklist.ShowDetails") }}</span>
            <span v-else>{{ $t("checklist.HideDetails") }}</span>
            <CloIcon
              symbol="triangleDown"
              :style="!details ? 'transform:rotate(180deg);' : ''"
            />
          </button>
          <button
            type="button"
            class="Lightbox-previous"
            @click="previous(false)"
            :disabled="currentSlide === 0"
            ref="previous"
          >
            <span class="is-visuallyHidden">{{ $t("generic.Previous") }}</span>
            <CloIcon symbol="arrowLeft" />
          </button>
          <button
            type="button"
            class="Lightbox-next"
            @click="next(next)"
            :disabled="currentSlide === mediaList.length - 1"
            ref="next"
          >
            <span class="is-visuallyHidden">{{ $t("generic.Next") }}</span>
            <CloLoadingAnimation
              v-if="currentSlide === mediaList.length - 1 && gettingMore"
              class="LoadingAnimation--small"
            >
              <CloIcon symbol="spinner" />
            </CloLoadingAnimation>
            <CloIcon v-else symbol="arrowRight" />
          </button>
        </div>
        <div tabindex="0" ref="focusTrapLast" class="is-visuallyHidden"></div>
      </div>
    </transition>
  </div>
</template>

<script>
// force polyfill for all browsers (but causes issues with keyboard navigation on chrome and ff)
//window.__forceSmoothScrollPolyfill__ = true;
//import smoothscroll from 'smoothscroll-polyfill';
// perhaps use this instead: https://github.com/magic-akari/seamless-scroll-polyfill
import axios from "axios";
import CloHeading from "@/base/CloHeading/CloHeading";
import CloLoadingAnimation from "@/base/CloLoadingAnimation/CloLoadingAnimation";
import CloIcon from "@/icons/CloIcon/CloIcon";
import CloSpectrogramPlayer from "@/media/CloSpectrogramPlayer/CloSpectrogramPlayer";
import CloVideoPlayer from "@/media/CloVideoPlayer/CloVideoPlayer";
import CloRating from "@/media/CloRating/CloRating";

export default {
  components: {
    CloHeading,
    CloLoadingAnimation,
    CloIcon,
    CloSpectrogramPlayer,
    CloVideoPlayer,
    CloRating,
  },
  data() {
    return {
      open: true,
      currentSlide: 0,
      scrollTimer: false,
      debounceTime: 100,
      scrollOptions: {
        behavior: "smooth",
        block: "center",
        inline: "center",
      },
      maxHeight: 360,
      hashPrefix: "#ml",
      shiftKey: false,
      animate: true,
      scrollsnap: true,
      details: false,
      getRatingUrl: null,
      requestMoreFromSlide: null,
      autoplay: true,
      initialListLength: 0,
      ratingData: {},
    };
  },
  props: {
    reverse: {
      type: Boolean,
      default: false,
    },
    side: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      default: "search",
    },
    list: {
      type: Array,
      required: true,
    },
    goTo: {
      type: String,
      default: "",
    },
    mediaDownloadUrl: {
      default: "https://cdn.download.ams.birds.cornell.edu/api/v1/asset/",
      type: String,
    },
    speciesLinks: {
      type: Boolean,
      default: true,
    },
    ratingEnabled: {
      type: Boolean,
      default: false,
    },
    getRatingsUrl: {
      type: String,
      default: "/rating-api?assetIds=",
    },
    saveRatingUrl: {
      type: String,
      default: "/rating-api",
    },
    ratingApiHeader: {
      type: String,
      default: null,
    },
    ratingCsrf: {
      type: String,
      default: null,
    },
    gettingMore: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    list: function () {
      // when we add to list, get more ratings
      if (this.ratingEnabled) {
        if (this.initialListLength < 100) {
          this.getRatings(10);
        }
      }
    },
  },
  computed: {
    direction() {
      if (document.querySelector("html").getAttribute("dir") === "rtl") {
        return "rtl";
      } else {
        return "ltr";
      }
    },
    lightboxInstructions() {
      const text = this.$t("media.lightboxInstructions");
      if (text === "media.lightboxInstructions") {
        return "Keyboard shortcuts: Press escape to close the dialog. Press space bar to play or pause audio and video. Press numbers 1 to 5 to rate media quality. Left and right arrow keys will visually navigate the list.";
      } else {
        return text;
      }
    },
    classes() {
      const classes = [];
      const prefix = "Lightbox--";
      if (this.reverse) {
        classes.push(prefix + "reverse");
      }
      if (this.side) {
        classes.push(prefix + "side");
      }
      return classes.join(" ");
    },
    mediaList() {
      const newList = [];
      this.list.forEach((item) => {
        const newItem = {};
        if (this.source === "search") {
          newItem.assetId = item.assetId;
          newItem.source = item.source;
          newItem.valid = item.valid;
          newItem.comName = item.commonName;
          newItem.sciName = item.sciName;
          newItem.userDisplayName = item.userDisplayName;
          newItem.userProfileUrl = item.userProfileUrl;
          newItem.date = item.obsDttm;
          newItem.location = item.location;
          newItem.mediaType = item.mediaType.slice(0, 1).toLowerCase();
          newItem.speciesUrl = item.ebirdSpeciesUrl;
          newItem.citationName = "ML" + item.assetId;
          newItem.citationUrl = item.specimenUrl;
          newItem.checklistUrl = item.eBirdChecklistUrl;
          newItem.checklistId = item.eBirdChecklistId;
          newItem.age = item.age;
          newItem.sex = item.sex;
          if (item.rating) {
            newItem.rating = Math.round(parseInt(item.rating));
          }
          if (item.ratingCount) {
            newItem.ratingCount = Math.round(parseInt(item.ratingCount));
          }
          newItem.details = item.comments;
          // restrict details to 80 characters
          if (newItem.details && newItem.details.length > 80) {
            newItem.details = newItem.details.slice(0, 80) + "...";
          }
          newItem.tags = {};
          if (item.subjectData[0].subjectTags.sounds) {
            newItem.tags.sounds = item.subjectData[0].subjectTags.sounds
              .map((x) => {
                return x.localizedString;
              })
              .join("; ");
          }
          if (item.subjectData[0].subjectTags.behaviors) {
            newItem.tags.behaviors = item.subjectData[0].subjectTags.behaviors
              .map((x) => {
                return x.localizedString;
              })
              .join("; ");
          }
          if (item.subjectData[0].subjectTags.breeding) {
            newItem.tags.breeding = item.subjectData[0].subjectTags.breeding
              .map((x) => {
                return x.localizedString;
              })
              .join("; ");
          }
          if (item.subjectData[0].subjectTags.tags_subject) {
            newItem.tags.tags = item.subjectData[0].subjectTags.tags_subject
              .map((x) => {
                return x.localizedString;
              })
              .join("; ");
          }
          if (item.source === "ml") {
            if (item.subjectData[0].ageSexCounts) {
              newItem.ageSexCounts = item.subjectData[0].ageSexCounts
                .map((x) => {
                  return x.localizedString;
                })
                .join("; ");
            }
          }
        }
        if (this.source === "gallery") {
          newItem.title = item.title;
          newItem.caption = item.caption;
          newItem.comName = item.asset.comName;
          newItem.sciName = item.asset.sciName;
          newItem.assetId = item.asset.assetId.toString();
          newItem.source = item.source;
          newItem.date = item.asset.obsDt;
          // isMap was for STEM videos, still used in Birds of the World historic accounts
          newItem.isMap = item.asset.isMap;
          if (item.asset.mediaContentType.indexOf("audio") !== -1) {
            newItem.mediaType = "a";
          } else if (item.asset.mediaContentType.indexOf("video") !== -1) {
            newItem.mediaType = "v";
          } else {
            newItem.mediaType = "p";
          }
          const locArray = [];
          if (item.asset.hasOwnProperty("stateName") && item.asset.stateName) {
            locArray.push(item.asset.stateName);
          }
          if (
            item.asset.hasOwnProperty("countryName") &&
            item.asset.countryName
          ) {
            locArray.push(item.asset.countryName);
          }
          newItem.location = locArray.join(", ");
          if (item.asset.hasOwnProperty("ebirdChecklistId")) {
            newItem.checklistId = item.asset.ebirdChecklistId;
            newItem.checklistUrl =
              "https://ebird.org/checklist/" + item.checklistId;
          }
          newItem.restricted = item.asset.restricted;
          newItem.credit = item.asset.credit;
          if (item.asset.citationUrl) {
            newItem.citationurl = item.asset.citationUrl;
            // citationName?
            newItem.userDisplayName = item.asset.creatorDisplayName;
          } else {
            newItem.citationurl = false;
            newItem.userDisplayName = item.asset.userDisplayName;
          }
        }
        if (this.source === "gallery3.0") {
          newItem.title = item.title;
          newItem.caption = item.caption;
          newItem.comName = item.asset.comName;
          newItem.sciName = item.asset.sciName;
          newItem.assetId = item.asset.assetId.toString();
          newItem.source = item.source;
          newItem.date = item.asset.obsDtDisplay;
          // isMap was for STEM videos, still used in Birds of the World historic accounts
          newItem.isMap = item.asset.isMap;
          newItem.mediaType = item.asset.assetFormatCode
            .slice(0, 1)
            .toLowerCase();
          const locArray = [];
          if (item.asset.hasOwnProperty("locName") && item.asset.locName) {
            locArray.push(item.asset.locName);
          }
          if (
            item.asset.hasOwnProperty("subnational2Name") &&
            item.asset.subnational2Name
          ) {
            locArray.push(item.asset.subnational2Name);
          }
          if (
            item.asset.hasOwnProperty("subnational1Name") &&
            item.asset.subnational1Name
          ) {
            locArray.push(item.asset.subnational1Name);
          }
          if (
            item.asset.hasOwnProperty("countryName") &&
            item.asset.countryName
          ) {
            locArray.push(item.asset.countryName);
          }
          newItem.location = locArray.join(", ");
          if (item.asset.hasOwnProperty("ebirdChecklistId")) {
            newItem.checklistId = item.asset.ebirdChecklistId;
            newItem.checklistUrl =
              "https://ebird.org/checklist/" + newItem.checklistId;
          }
          if (item.asset.hasOwnProperty("subId")) {
            newItem.checklistId = item.asset.subId;
            newItem.checklistUrl =
              "https://ebird.org/checklist/" + newItem.checklistId;
          }
          newItem.restricted = item.asset.restricted;
          newItem.credit = item.asset.credit;
          newItem.speciesUrl = item.ebirdSpeciesUrl;
          if (item.asset.citationUrl) {
            newItem.citationUrl = item.asset.citationUrl;
            newItem.citationName = item.asset.citationName;
          } else if (
            item.asset.parentAssetId &&
            !item.asset.parentAssetRestricted
          ) {
            newItem.citationUrl =
              "https://macaulaylibrary.org/asset/" + item.asset.parentAssetId;
            newItem.citationName = "ML" + item.asset.parentAssetId;
          }
          newItem.userDisplayName = item.asset.userDisplayName;
        }
        newList.push(newItem);
      });
      return newList;
    },
  },
  mounted() {
    //smoothscroll.polyfill();
    if (this.open) {
      // TODO: undo this on destroy, do it on open event
      document.querySelector("body").setAttribute("data-noscroll", true);
    }
    if (this.ratingEnabled) {
      this.initialListLength = this.mediaList.length;
      if (this.initialListLength < 100) {
        this.getRatings(this.initialListLength);
      } else {
        // too many, so each rating will fetch it's own data
        // note singular/plural difference
        this.getRatingUrl = this.getRatingsUrl;
      }
    }
    this.setMaxMediaDimensions();
    this.handleResize();
    // only first asset should autoplay
    // dangerous setTimeout for autoplay
    // if lightbox is closed immediately could be problematic
    // so we check for undefined?
    setTimeout(() => {
      if (typeof this.autoplay !== "undefined") {
        this.autoplay = false;
      }
    }, 1000);
    if (this.goTo) {
      this.goToAsset(this.goTo, true);
    } else {
      this.setHash(true);
      this.$refs.lightbox.focus();
    }
    /*
		window.addEventListener('popstate', (e) => {
			this.close();
			//window.history.replaceState('', window.document.title, window.location.pathname + window.location.search);
		});
		*/
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("data-noscroll");
  },
  methods: {
    goToAsset(assetId, pushHash) {
      this.animate = false;
      let slide = 0;
      this.mediaList.forEach((item, index) => {
        if (item.assetId == assetId) {
          slide = index;
        }
      });
      if (slide !== 0) {
        this.$nextTick(() => {
          this.$refs.slides[slide].scrollIntoView();
          this.animate = true;
        });
      }
      this.$refs.slides[slide].focus();
      if (pushHash) {
        this.setHash(true);
      }
    },
    close() {
      // TODO: focus on opener
      this.open = false;
      document.querySelector("body").removeAttribute("data-noscroll");
      //window.history.replaceState('', window.document.title, window.location.pathname + window.location.search);
      const restoreFocus = document.querySelector(
        '[data-asset-id="' + this.goTo + '"]'
      );
      if (restoreFocus) {
        restoreFocus.focus();
      }
      // send event for parent to destroy lightbox, delay for fadeout
      setTimeout(() => {
        this.$emit("close");
      }, 200);
    },
    previous(focusOnAsset) {
      if (this.currentSlide > 0) {
        this.currentSlide--;
        this.$nextTick(() => {
          this.setMaxMediaDimensions();
          if (focusOnAsset) {
            this.$refs.slides[this.currentSlide].focus();
          }
        });
        //this.$refs.slides[this.currentSlide].scrollIntoView(this.scrollOptions);
        this.animateScrollTo(-1);
      }
    },
    next(focusOnAsset) {
      if (this.currentSlide < this.mediaList.length - 1) {
        this.currentSlide++;
        this.$nextTick(() => {
          this.setMaxMediaDimensions();
          if (focusOnAsset) {
            this.$refs.slides[this.currentSlide].focus();
          }
        });
        //this.$refs.slides[this.currentSlide].scrollIntoView(this.scrollOptions);
        this.animateScrollTo(1);
      }
    },
    left() {
      if (this.direction === "rtl") {
        this.next(true);
      } else {
        this.previous(true);
      }
    },
    right() {
      if (this.direction === "rtl") {
        this.previous(true);
      } else {
        this.next(true);
      }
    },
    animateScrollTo(direction) {
      this.scrollsnap = false;
      const carousel = this.$refs.carousel;
      const slideWidth = carousel.clientWidth;
      const totalWidth = slideWidth * this.mediaList.length;
      const dir = this.direction === "rtl" ? -1 : 1; // positions are negative for rtl
      const currentPosition =
        slideWidth * (this.currentSlide - direction) * dir;
      const targetPosition = currentPosition + direction * slideWidth * dir;
      //console.log('cur, tar', currentPosition, targetPosition);
      const time = 0.3; // seconds
      let currentTime = 0;
      const tick = () => {
        currentTime += 1 / 60;
        const p = currentTime / time;
        // ease in and out
        const t = -0.5 * (Math.cos(Math.PI * p) - 1);
        if (p < 1) {
          requestAnimationFrame(tick);
          carousel.scrollLeft =
            currentPosition + (targetPosition - currentPosition) * t;
        } else {
          // scroll is done
          carousel.scrollLeft = targetPosition;
          this.scrollsnap = true;
        }
      };
      tick();
    },
    toggleMediaPlay() {
      if (this.mediaList[this.currentSlide].mediaType === "a") {
        event = new CustomEvent("lichen:spect-togglePlay", {
          detail: {
            assetId: parseInt(this.mediaList[this.currentSlide].assetId),
          },
        });
        document.dispatchEvent(event);
      }
      if (this.mediaList[this.currentSlide].mediaType === "v") {
        event = new CustomEvent("lichen:video-togglePlay", {
          detail: {
            assetId: parseInt(this.mediaList[this.currentSlide].assetId),
          },
        });
        document.dispatchEvent(event);
      }
    },
    handleScroll(e) {
      window.clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        const position = e.target.scrollLeft;
        let currentSlide = Math.round(position / e.target.clientWidth);
        if (this.direction === "rtl") {
          currentSlide = Math.round((-1 * position) / e.target.clientWidth);
        }
        if (currentSlide !== this.currentSlide) {
          this.currentSlide = currentSlide;
        }
        this.setHash();
        // send event only once if on last slide
        if (this.currentSlide == this.mediaList.length - 1) {
          if (this.requestMoreFromSlide !== this.currentSlide) {
            this.requestMoreFromSlide = this.currentSlide;
            this.$emit("get-more-slides");
          }
        }
      }, this.debounceTime);
    },
    handleResize() {
      document.addEventListener("lichen:resize", () => {
        this.$nextTick(() => {
          if (this.$refs.slides && this.$refs.slides[this.currentSlide]) {
            this.setMaxMediaDimensions();
            this.$refs.slides[this.currentSlide].scrollIntoView();
          }
        });
      });
    },
    setMaxMediaDimensions() {
      if (this.$refs.mediaContainer[0]) {
        this.maxHeight = this.$refs.mediaContainer[0].offsetHeight;
        this.maxWidth = this.$refs.mediaContainer[0].offsetWidth;
      }
    },
    setHash(pushState) {
      /*if (pushState) {
				window.history.pushState('', window.document.title, this.hashPrefix + this.mediaList[this.currentSlide].assetId);
			} else {
				window.history.replaceState('', window.document.title, this.hashPrefix + this.mediaList[this.currentSlide].assetId);
			}*/
    },
    handleTab(e) {
      // trap focus inside role="dialog"
      if (!e.shiftKey) {
        // if we are on the last focusable element, focus on the lightbox, show first slide
        if (e.target === this.$refs.focusTrapLast) {
          this.$refs.lightbox.focus();
          this.$refs.slides[0].scrollIntoView();
          this.setHash(true);
        } else {
          // prevent keeping focus on focusTrap, focus on next element (close)
          if (e.target === this.$refs.focusTrapFirst) {
            this.$refs.close.focus();
          }
        }
      } else {
        // if we are on the first focusable element, and focus on the last slide
        if (e.target === this.$refs.focusTrapFirst) {
          this.$refs.slides[this.mediaList.length - 1].focus();
        }
      }
    },
    toggleDetails() {
      this.details = !this.details;
    },
    getRatings(count) {
      // get ratings for the last "count" number of assets
      // when more assets are added to the list, this
      // only fetches ratings for the new ones
      const assetIds = this.mediaList
        .map((item, index) => {
          if (this.mediaList.length - count <= index) {
            return item.assetId;
          }
        })
        .filter((item) => {
          return item != null;
        })
        .join(",");
      const url = this.getRatingsUrl + assetIds;
      const headers = {
        "X-Requested-With": "XMLHttpRequest",
      };
      if (this.ratingApiHeader) {
        headers["Session-Inheritance-Config"] = this.ratingApiHeader;
      }
      if (this.ratingCsrf) {
        headers["X-CSRF-TOKEN"] = this.ratingCsrf;
      }
      axios
        .get(url, null, headers)
        .then((res) => {
          // keep cache of rating data
          this.ratingData = Object.assign(this.ratingData, res.data);
          this.mediaList.forEach((item) => {
            if (res.data.hasOwnProperty(item.assetId)) {
              const ratingData = res.data[item.assetId];
              item.rating = Math.round(ratingData.ratingAverage);
              item.count = ratingData.ratingCount;
              item.userRating = ratingData.rating;
            } else {
              // use cache for assets already in list
              const ratingData = this.ratingData[item.assetId];
              item.rating = Math.round(ratingData.ratingAverage);
              item.count = ratingData.ratingCount;
              item.userRating = ratingData.rating;
            }
          });
          this.$forceUpdate();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleRating(e) {
      if (!e.shiftKey && !e.metaKey) {
        switch (e.which) {
          case 49: // 1
          case 97:
            this.handleRatingByKey(1, e);
            break;
          case 50: // 2
          case 98:
            this.handleRatingByKey(2, e);
            break;
          case 51: // 3
          case 99:
            this.handleRatingByKey(3, e);
            break;
          case 52: // 4
          case 100:
            this.handleRatingByKey(4, e);
            break;
          case 53: // 5
          case 101:
            this.handleRatingByKey(5, e);
            break;
        }
      }
    },
    handleRatingByKey(rating, e) {
      e.preventDefault();
      e.stopPropagation();
      this.mediaList[this.currentSlide].ratingFromParent = rating;
      this.$forceUpdate();
    },
    // event that comes from rating component when rating is saved
    applyRatingToList(savedRating) {
      this.mediaList.forEach((item) => {
        if (item.assetId === savedRating.assetId.toString()) {
          item.rating = Math.round(savedRating.ratingAverage);
          item.count = savedRating.ratingCount;
          item.userRating = savedRating.rating;
          this.$forceUpdate();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@keyframes lightboxFadeIn {
  0% {
    opacity: 0;
    display: none;
  }
  99% {
    display: block;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

body[data-noscroll] {
  overflow: hidden;
}

.Lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1006;
  animation: 0.2s ease lightboxFadeIn;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity 0.2s;
  }
  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }
  .Lightbox-close {
    position: absolute;
    top: $space-sm;
    right: $space-sm;
    [dir="rtl"] & {
      right: auto;
      left: $space-sm;
    }
    @include inset-sm;
    z-index: 1007;
    background-color: $color-neutral-1;
    line-height: 1;
    .Icon {
      fill: $color-neutral-6;
    }
    &:hover {
      background-color: $color-neutral-0;
    }
  }
  .Lightbox-navigation {
    display: flex;
    gap: $space-xs;
    @include inset-xs;
    .Lightbox-toggleDetails {
      display: flex;
      justify-content: center;
      align-items: center;
      .Icon {
        @include icon-sm;
        transition: transform 0.2s ease;
      }
    }
    .Lightbox-toggleDetails,
    .Lightbox-previous,
    .Lightbox-next {
      flex: 1 0 auto;
      @include text(2);
      @include inset-sm;
      background-color: $color-neutral-1;
      color: $color-neutral-6;
      &[disabled] .Icon {
        fill: $color-neutral-2;
      }
      &:hover:not([disabled]) {
        background-color: $color-neutral-0;
      }
      // getting more results
      .Icon--spinner {
        fill: $color-secondary !important;
      }
    }
    @include breakpoint(medium) {
      .Lightbox-toggleDetails {
        display: none;
      }
    }
    @include breakpoint(medium) {
      position: fixed;
      width: 100%;
      top: 0;
      padding: 0;
      .Lightbox-previous,
      .Lightbox-next {
        position: absolute;
        top: -20px;
      }
      .Lightbox-previous {
        left: $space-sm;
        [dir="rtl"] & {
          left: auto;
          right: $space-sm;
        }
      }
      .Lightbox-next {
        right: $space-sm;
        [dir="rtl"] & {
          right: auto;
          left: $space-sm;
        }
      }
    }
  }
  .Lightbox-carousel {
    list-style: none;
    margin: 0;
    display: flex;
    height: 100%;
    overflow-y: auto;
    //scroll-snap-type: x mandatory;
    // hide the scrollbar
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    // Safari 10.1+
    // https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome
    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        scroll-snap-type: x mandatory;
      }
    }
  }
  .Lightbox-slide {
    height: 100%;
    width: 100vw;
    display: flex;
    flex: 1 0 auto;
    scroll-snap-align: center;
    outline: 0;
  }
  .Lightbox-figure {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .Lightbox-media-outer {
    flex-grow: 1;
    height: 100%;
    position: relative;
  }
  .Lightbox-media-inner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // hide overflow unfortunately needed for mobile
    // when expanding details with audio or video player above
    overflow: hidden;
    &.fade-enter-active,
    &.fade-leave-active {
      transition: opacity 0.2s;
    }
    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }
    .Lightbox-image {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto !important;
      // Safari 10.1+
      // https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome
      // this is fine with all browsers except IE 11, which is why we need safari only
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          position: absolute;
        }
      }
    }
    .SpectrogramPlayer {
      width: 100%;
      max-width: 70rem;
    }
  }
  .Lightbox-caption {
    flex-grow: 0;
    justify-self: flex-end;
    @include text(2, loose);
    .Lightbox-obs {
      .Icon {
        @include icon-sm;
        @include inline-xs;
        fill: $color-neutral-3;
        flex-shrink: 0;
      }
    }
    .Lightbox-obs,
    .Lighbox-meta {
      > div {
        display: flex;
        align-items: center;
        max-width: 100%;
      }
    }
    .Lightbox-meta-label {
      @include text(1);
      @include inline-xs;
      color: $color-neutral-4;
      text-transform: uppercase;
    }
    .Lightbox-links {
      a {
        @include text(2);
        display: inline-flex;
        align-items: center;
        .Icon {
          @include icon-sm;
          @include inline-xs;
          fill: currentColor;
        }
      }
    }
  }
  @include breakpoint(medium) {
    .Lightbox-caption-main,
    .Lightbox-caption-details {
      // IE 11 fallback
      display: flex;
      justify-content: space-between;
      > * {
        margin-right: $space-sm;
      }
      @supports (display: grid) {
        // make parent be grid or flex parent
        // and children be grid or flex children
        display: contents;
        > * {
          margin-right: 0;
        }
      }
    }
    .Lightbox-caption-details {
      max-height: none !important;
      overflow: visible !important;
    }
  }
  .Lightbox-caption-inner {
    &.fade-enter-active,
    &.fade-leave-active {
      transition: opacity 0.2s;
    }
    &.fade-enter,
    &.fade-leave-to {
      opacity: 0;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .Lightbox-speciesLabels {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      @include stack-sm;
      @include breakpoint(medium) {
        margin: 0;
      }
    }
    .Lightbox-galleryLabels {
      word-break: break-word;
    }
    .Lightbox-unconfirmed {
      @include inline-xs;
      color: $color-alert;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      .Icon {
        @include icon-sm;
      }
      span {
        display: none;
        @include text(1);
        text-transform: uppercase;
      }
      @include breakpoint(large) {
        @include inline-sm;
        .Icon {
          @include icon-md;
          @include stack-xs;
        }
        span {
          display: block;
        }
      }
    }
    @include inset-sm;
    @include breakpoint(medium) {
      @include inset-md;
      display: grid;
      gap: $space-sm;
      justify-content: stretch;
      grid-template-columns: minmax(auto, 50%) minmax(auto, 50%) minmax(
          auto,
          25%
        );
      .Lightbox-heading {
        grid-column: 1/3;
        grid-row: 1/2;
      }
      .Lightbox-obs {
        grid-column: 1/2;
        grid-row: 2/2;
      }
      .Lightbox-meta {
        grid-column: 2/3;
        grid-row: 2/2;
      }
      .Lightbox-links {
        grid-column: 3/3;
        grid-row: 2/2;
      }
      .Lightbox-rating {
        grid-column: 3/3;
        grid-row: 1/2;
      }
    }
    @include breakpoint(large) {
      overflow-y: visible;
    }
  }
}

@mixin Lightbox-side {
  .Lightbox-figure {
    flex-direction: row;
  }
  .Lightbox-media-inner {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    height: 100%;
  }
  .Lightbox-caption {
    width: 20rem;
    max-width: 33vw;
    @include breakpoint(large) {
      max-width: 25vw;
    }
    padding: 3rem 0;
    border-left: 1px solid $color-neutral-1;
    display: flex;
    align-items: center;
    [dir="rtl"] & {
      border-left: 0;
      border-right: 1px solid $color-neutral-1;
    }
    .Lightbox-speciesLabels {
      margin-bottom: $space-sm !important;
    }
  }
  .Lightbox-caption-inner {
    display: flex;
    height: calc(100% - 3rem);
    overflow-y: auto;
    // overflow-x: visible is not possible, overflow-y overides it
    // hack needed for rating widget to fully show
    // https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue
    padding-left: 250px;
    margin-left: calc(-250px + 1rem);
  }
  .Lightbox-toggleDetails {
    display: none !important;
  }
  .Lightbox-caption-details {
    max-height: none !important;
    overflow: visible !important;
  }
  .Lightbox-navigation {
    position: fixed;
    width: auto;
    padding: 0;
    background-color: $color-white;
    top: auto !important;
    bottom: $space-sm;
    right: $space-sm;
    [dir="rtl"] & {
      right: auto;
      left: $space-sm;
    }
    .Lightbox-previous,
    .Lightbox-next {
      right: auto;
      left: auto;
      top: auto;
      position: relative;
      [dir="rtl"] & {
        right: auto;
        left: auto;
      }
    }
  }
  &.Lightbox--reverse {
    .Lightbox-close {
      background-color: $color-neutral-6;
    }
    .Lightbox-navigation {
      .Lightbox-previous,
      .Lightbox-next {
        background-color: $color-neutral-6;
      }
    }
    .Lightbox-caption {
      border: 0;
    }
  }
}

.Lightbox {
  @include breakpoint(large, down) {
    @media (orientation: landscape) {
      @include Lightbox-side;
    }
  }
}

.Lightbox--side {
  @include breakpoint(large) {
    @include Lightbox-side;
  }
}

.Lightbox--reverse {
  background-color: $color-neutral-6;
  color: $color-white;
  .Lightbox-close {
    background-color: rgba(64, 64, 64, 0.5);
    .Icon {
      fill: $color-neutral-0;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .Lightbox-navigation {
    background-color: $color-neutral-5;
    .Lightbox-toggleDetails,
    .Lightbox-previous,
    .Lightbox-next {
      background-color: $color-neutral-6;
      color: $color-neutral-2;
      &[disabled] .Icon {
        fill: $color-neutral-4;
      }
      &:hover:not([disabled]) {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    @include breakpoint(medium) {
      background-color: transparent;
      .Lightbox-previous,
      .Lightbox-next {
        color: $color-neutral-0;
        background-color: rgba(64, 64, 64, 0.5);
      }
    }
  }
  .Lightbox-caption {
    background-color: $color-neutral-5;
    border: 0;
    a {
      color: $color-link-reverse;
    }
  }
  .Lightbox-caption-inner {
    .Lightbox-meta-label {
      color: $color-neutral-2;
    }
  }
}
</style>
