import Vue from "vue";

// main.js includes global styles and utilities
import "@/base/global-js/main.js";

// Component dependencies

// base
import CloButton from "@/base/CloButton/CloButton";
Vue.component("CloButton", CloButton);

import CloHeading from "@/base/CloHeading/CloHeading";
Vue.component("CloHeading", CloHeading);

import CloPage from "@/base/CloPage/CloPage";
Vue.component("CloPage", CloPage);

import CloSkipTo from "@/base/CloSkipTo/CloSkipTo";
Vue.component("CloSkipTo", CloSkipTo);

import CloTable from "@/base/CloTable/CloTable";
Vue.component("CloTable", CloTable);

import CloDivider from "@/base/CloDivider/CloDivider";
Vue.component("CloDivider", CloDivider);

import CloUnorderedList from "@/base/CloUnorderedList/CloUnorderedList";
Vue.component("CloUnorderedList", CloUnorderedList);

// grid
import CloGridFlex from "@/grid/CloGridFlex/CloGridFlex";
Vue.component("CloGridFlex", CloGridFlex);

// icons
import CloIcon from "@/icons/CloIcon/CloIcon";
Vue.component("CloIcon", CloIcon);

// layouts
import CloSectionHeading from "@/layouts/CloSectionHeading/CloSectionHeading";
Vue.component("CloSectionHeading", CloSectionHeading);

// media
import CloResultsGallery from "@/media/CloResultsGallery/CloResultsGallery";
Vue.component("CloResultsGallery", CloResultsGallery);

import CloMediaFeed from "@/media/CloMediaFeed/CloMediaFeed";
Vue.component("CloMediaFeed", CloMediaFeed);

// deprecated
import CloHeaderBeta from "@/deprecated/CloHeaderBeta";
Vue.component("CloHeaderBeta", CloHeaderBeta);

import CloPanel from "@/deprecated/CloPanel";
Vue.component("CloPanel", CloPanel);

import GlobalUtilities from "./GlobalUtilities";
Vue.component("GlobalUtilities", GlobalUtilities);

import RecentPhotos from "./RecentPhotos";
Vue.component("RecentPhotos", RecentPhotos);

import SpeciesList from "./SpeciesList";
Vue.component("SpeciesList", SpeciesList);

// internationalization
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "locale",
  fallbackLocale: "locale",
  messages: {
    locale: window.langMap,
  },
});

// initialize components on page load
window.addEventListener("DOMContentLoaded", function () {
  if (document.querySelector("[data-lichen-vue]")) {
    var els = document.querySelectorAll("[data-lichen-vue]");
    Array.prototype.forEach.call(els, function (el) {
      new Vue({ el: el, i18n });
    });
  }
});
